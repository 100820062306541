import * as React from "react"
import { Link } from "gatsby"
import { AppBar, Box } from "@mui/material"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <h1 className="main-heading">
        <Link to="/">{title}</Link>
      </h1>
    )
  } else {
    header = (
      <Link className="header-link-home" to="/">
        {title}
      </Link>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <AppBar position="static" sx={{ p: 2 }}>{header}</AppBar>
      <Box component="main" sx={{ mx: "auto", my: 2, maxWidth: "42rem", }}>{children}</Box>
      <Box component="footer" sx={{
        textAlign: "center",
        borderTop: "1px solid gray"
      }}>
        <p>Copyright © kangju  {new Date().getFullYear()}</p>
      </Box>
    </div>
  )
}

export default Layout
